<template>
  <div class="appointment">
    <mu-container>
      <mu-row>
        <mu-col span="12">
          <mu-card style="width: 100%; margin: 0px auto; padding-bottom: 20px">
            <mu-card-media>
              <img src="/static/images/jiandang_top.jpg" />
            </mu-card-media>
            <mu-card-title
              title="可恩口腔企业微信建档通道"
              sub-title="建档信息会直接存入his系统"
            ></mu-card-title>
            <mu-card-text>
              <van-form @submit="submit"> 

                <van-cell-group title="开发人员">
                  <van-field v-model="user.name" label="开发人员" required :rules="[{ required: true, message: '请输入开发人员！' }]" readonly/>
                  <van-field v-model="orderForm.kfrybm" label="开发人员编码" placeholder="请先开通his账号,有问题联系王时霞" required :rules="[{ required: true, message: '请输入开发人员编码！' }]" readonly/>
                </van-cell-group>
                <van-notice-bar
                  
                  left-icon="volume-o"
                  :scrollable="false"
                  wrapable
                  style="text-align:left"
                  text="开发人员编码如为空，请先联系人资开通HIS账号，HIS账号需要与企微账号保持一致，开通后1小时后自动更新，有问题请联系王时霞。"
                />

                <van-cell-group title="患者信息">
                  <van-field v-model="orderForm.name" label="患者姓名" placeholder="请输入患者姓名" required :rules="[{ required: true, message: '请选择患者姓名！' }]"/>
                  <van-field v-model="orderForm.lxrgx_name" label="患者是联系人的" required placeholder="请选择关系" @click="relations.show = true" :rules="[{ required: true, message: '请选择关系' }]" readonly is-link/>
                  <van-action-sheet v-model="relations.show" :actions="relations.data" @select="onSelectLxrgx" />

                  <van-field v-model="orderForm.mobile" label="联系人手机号" type="digit" placeholder="请输入联系人手机号码" @blur="getPatient()" required :rules="[{ required: true, message: '请请输入联系人手机号码！' }]"/>
                  <van-field v-model="orderForm.lxrxm" label="联系人姓名" placeholder="请输入联系人姓名" required :rules="[{ required: true, message: '请输入联系人姓名！' }]" v-if="orderForm.lxrgx !=1 "/>
                  
                  <van-field name="radio" label="患者性别" required :rules="[{ required: true, message: '请选择性别！' }]">
                    <template #input>
                      <van-radio-group v-model="orderForm.sex" direction="horizontal">
                        <van-radio name="1">男</van-radio>
                        <van-radio name="2">女</van-radio>
                        <van-radio name="3">未知</van-radio>
                      </van-radio-group>
                    </template>
                  </van-field>
                  <SelectItem label="缺牙数量" :value="orderForm.qysl_name" :options="qyslOptions" @onSelect="onSelectQysl" />
                  <van-field v-model="orderForm.sfzh" label="身份证号码" placeholder="请输入身份证号码" @blur="validateIdCard(orderForm.sfzh)" />
                  <van-field v-model="orderForm.txdz" label="通讯地址" placeholder="请输入通讯地址" />
                </van-cell-group>
                <Lyqd :orderForm="orderForm" :dicts="dicts" @change="changeLyqdData" />   
                <van-cell-group title="建档机构">
                  <van-field 
                    v-model="orderForm.jg_name" 
                    label="建档机构" 
                    required 
                    placeholder="请选择建档机构" 
                    @click="openHospital" 
                    :rules="[{ required: true, message: '请选择建档机构' }]" 
                    readonly 
                    is-link
                  />
                  <van-action-sheet 
                    v-model="hospitals.show" 
                    :actions="hospitals.data" 
                    @select="onSelectHospital" 
                  />
                  <van-field 
                    v-model="orderForm.yyks_name" 
                    label="建档科室" 
                    required 
                    placeholder="请选择建档科室" 
                    @click="offices.show = true" 
                    :rules="[{ required: true, message: '请选择建档科室' }]" 
                    readonly 
                    is-link
                  />
                  <van-action-sheet v-model="offices.show" :actions="offices.data" @select="selectOffice" />
                </van-cell-group>             
                <van-cell-group style="margin-bottom:10px;">
                  <van-field 
                    rows="1"
                    autosize
                    type="textarea"
                    v-model="orderForm.remark" 
                    placeholder="请输入备注内容" 
                    label="备注" 
                  />
                </van-cell-group>
                
                <!-- <mu-button
                  large
                  full-width
                  round
                  
                  v-loading="loading"
                  @click="submit()"
                  color="green500"
                  :disabled="submit_disabled"
                  >提交建档</mu-button
                > -->
                <van-button 
                  type="primary" 
                  round 
                  :loading="loading" 
                  :disabled="submit_disabled" 
                  native-type="submit"
                  block>
                  提交建档
                </van-button>
              </van-form>
            </mu-card-text>
          </mu-card>
          <mu-dialog
            title="选择就诊人"
            width="360"
            :open.sync="his_patients.show"
          >
            <mu-list>
              <mu-form model="form">
                <mu-list-item
                  :key="item.brid"
                  v-for="item in his_patients.data"
                >
                  <mu-list-item-content>
                    <mu-radio
                      :label="item.name"
                      :value="item.brid"
                      v-model="orderForm.brid"
                      @change="confirmPatient(item)"
                    ></mu-radio>
                  </mu-list-item-content>
                </mu-list-item>
              </mu-form>
            </mu-list>

            <mu-button
              slot="actions"
              flat
              color="green500"
              @click="his_patients.show = false"
              
            >确定</mu-button>
          </mu-dialog>
        </mu-col>
      </mu-row>
    </mu-container>
    
    <WxWorkFooter shift="jiandang" />
  </div>
</template>

<script>
// @ is an alias to /src
import WxWorkFooter from "../../components/WxWorkFooter.vue";
import Lyqd from "./components/Lyqd.vue";
import SelectItem from "./components/SelectItem.vue";
import dayjs from 'dayjs'
import {
  validate,
  province,
  birthDate,
  gender,
  pattern,
} from 'chinese-idcard-checker';

export default {
  name: "Home",
  components: { WxWorkFooter, Lyqd,SelectItem },
  data() {
    return {
      dicts: [],
      loading: false,
      submit_disabled: true,
      user: {},
      hospitals: {
        show: false,
        data: [],
      },
      offices: {
        show: false,
        data: [],
      },
      qyslOptions:[],
      orderForm: {
        kfry_userid: "",
        kfrybm: "",
        mobile: "",
        brid: "",
        sfzh:'',
        txdz:'',
        qysl:'',
        qysl_name:'',
        csrq:'',
        lxrgx: 1, //联系人关系
        lxrgx_name: "本人", //关系名称
        lxrxm: "", //联系人姓名
        sex: "",
        name: "",
        city_name: "",
        city_code: "",
        lybm: "", //来源编码
        qdbm: "", //渠道编码
        jgbm: "",
        jg_name: "",
        is_daiyuyue: 1,

        jsrbm: "", // 介绍人病人id
        jsrxm: "", // 介绍人姓名
        jsrsjh: "", // 介绍人手机号
        yhzgxbm: "", // 介绍人关系编码
        dzysbm: "", // 点诊医生
        dzzjzlbm: "", // 点诊专家助理
        ykfrgxbm: "", // 与开发人关系id
        ykfrgx: "", // 与开发人关系

        yyksbm: "", //建档科室编码
        yyks_name: "", //建档科室名称
        hhrid: "", //合伙人id
        hhr_name: "", //合伙人姓名
        hhrtjrbm: "", //合伙人推荐人编码
        hhrtjrxm: "", //合伙人推荐人姓名
        hzptid: "", //合作平台id
        hzpt_name: "", //合作平台名称
        xmfzrybm: "",
        xmfzrymc: "",
      },
      his_patients: {
        show: false,
        data: [],
      },
      relations: {
        show: false,
        data: [],
      },
    };
  },
  created() {
    this.getUserinfo();
    setTimeout(() => {
      this.getOffices();
      this.getDicts();
    }, 1000);
    document.title = "患者建档";
  },
  methods: {
    getUserinfo() {
      this.$http.get("user_info").then((res) => {
        if (res.data.code == 204) {
          this.$router.push({ name: "WxWorkLogin" });
          return false;
        }
        this.user = res.data.data;
        this.orderForm.kfry_userid = res.data.data.id;
        this.orderForm.kfrybm = res.data.data.rybm;
      });
    },
    async getDicts() {
      const res = await this.$http.get("dict")
      if (res.data.code !== 200) return false
      this.dicts = res.data.data;
      this.relations.data = res.data.data.filter((item) => {
        return item.flbm == 1038;
      });
      this.qyslOptions = res.data.data.filter((item) => {
        return item.flbm == 1054;
      });
    },
    getPatient() {
      this.orderForm.brid = "";
      // this.orderForm.name = "";
      this.his_patients.data = [];
      //恢复
      this.orderForm.lxrgx = 1;
      this.orderForm.lxrxm = "";
      this.orderForm.sex = "";
      if (this.orderForm.mobile.length < 10) {
        return false;
      }
      this.$http.post("his_patient", this.orderForm).then((res) => {
        if (res.data.code == 201) {
          this.submit_disabled = false;
          return false;
        }

        //默认选择第一个
        // this.orderForm.brid = res.data.data[0].brid;
        // this.orderForm.name = res.data.data[0].xm;
        // this.orderForm.sex = res.data.data[0].xb;
        // this.his_patients.show = true;
        // this.his_patients.data = res.data.data;
        this.$dialog.alert({
          message: "His内存在该手机号，不能重复建档！",
          theme: "round-button",
        });
        this.submit_disabled = true;
        return false;
      });
    },
    confirmPatient(patient) {
      // this.orderForm.brid = patient.brid;
      this.orderForm.name = patient.xm;
      this.orderForm.sex = patient.xb;
      this.his_patients.show = false;
    },

    //选择联系人关系
    onSelectLxrgx(item) {
      this.relations.show = false;
      this.orderForm.lxrgx = item.zdbm;
      this.orderForm.lxrgx_name = item.name;
    },
    //更新来源渠道后  更新数据
    // changeLyQd(item) {
    //   this.orderForm.city_code = item.city_code
    //   this.orderForm.lybm = item.lybm;
    //   this.orderForm.qdbm = item.qdbm;
    //   this.orderForm.jgbm = item.jgbm;
    //   this.orderForm.jsrbm = item.jsrbm;
    //   this.orderForm.jsrxm = item.jsrxm;
    //   this.orderForm.jsrsjh = item.jsrsjh;
    //   this.orderForm.yhzgxbm = item.yhzgxbm;
    //   this.orderForm.ykfrgxbm = item.ykfrgxbm;
    //   this.orderForm.dzysbm = item.dzysbm;
    //   this.orderForm.dzzjzlbm = item.dzzjzlbm;
    //   this.orderForm.hzptid = item.hzptid
    //   this.orderForm.hzpt_name = item.hzpt_name
    // },
    changeLyqdData(key, value) {
      this.orderForm[key] = value;
      if (key === "city_code") {
        this.getHospital();
      }
    },
    // onSelectPartner(item){
    //   this.orderForm.hhrid = item.id
    //   this.orderForm.hhr_name = item.name
    //   this.orderForm.hhrtjrbm = item.tjrbm
    //   this.orderForm.hhrtjrxm = item.tjrxm
    // },
    getHospital() {
      this.orderForm.jgbm = "";
      this.orderForm.jg_name = "";
      this.$http
        .get("hospital_city", { params: this.orderForm })
        .then((res) => {
          // if (res.data.code == 204) {
          //   this.$router.push({name:'WxWorkLogin'});
          //   return false;
          // }
          this.hospitals.data = res.data.data;
        });
    },
    openHospital() {
      if (!this.orderForm.city_code) {
        this.$dialog.alert({
          message: "请先选择城市！",
          theme: "round-button",
        });
        return false;
      }
      this.hospitals.show = true;
    },
    onSelectHospital(item) {
      this.orderForm.jg_name = item.name;
      this.orderForm.jgbm = item.jgbm;
      this.hospitals.show = false;
    },
    getOffices() {
      this.$http.get("office").then((res) => {
        this.offices.data = res.data.data;
        this.offices.data.push({
          yyksbm: 0,
          name: "其它",
        });
      });
    },
    selectOffice(item) {
      this.orderForm.yyks_name = item.name;
      this.orderForm.yyksbm = item.yyksbm;
      this.offices.show = false;
    },
    onSelectQysl(item){
      // consoel.log(item)
      this.orderForm.qysl = item.zdbm
      this.orderForm.qysl_name = item.name
    },
    validateIdCard(idcard) {
      // console.log(idcard)
      if(!idcard) return true;
      if(!validate(idcard)) {
        this.$dialog.alert({
          message: "身份证号码格式不正确,请重新输入!",
          theme: "round-button",
        });
        return false;
      }
      this.orderForm.csrq = dayjs(birthDate(idcard)).format('YYYY-MM-DD')
      // console.log(this.orderForm.csrq)
      return true;
      
    },

    submit() {
      // console.log(this.orderForm)
      // return;
      // this.loading = true;
      // return false;
      const flag = this.validateIdCard(this.orderForm.sfzh)
      if(!flag) return;
   
      if (this.orderForm.lxrgx == 1) {
        this.orderForm.lxrxm = this.orderForm.name;
      }
      if (this.orderForm.mobile.length != 11) {
        this.$dialog.alert({
          message: "请输入11位手机号码",
          theme: "round-button",
        });
        return false;
      }
      // console.log(this.orderForm)
      // return false;
      if (this.orderForm.kfrybm == "" || this.orderForm.kfrybm == null) {
        this.$dialog.alert({
          message: "开发人员编码为空，请联系管理员！",
          theme: "round-button",
        });
        return false;
      }
      if (this.orderForm.jgbm == "" || this.orderForm.jgbm == null) {
        this.$dialog.alert({
          message: "机构编码为空，请选择机构！",
          theme: "round-button",
        });
        return false;
      }
      if (this.orderForm.brid) {
        this.$dialog.alert({
          message: "系统已存在该就诊人，无需再次建档！",
          theme: "round-button",
        });
        return false;
      }
      if (this.loading) {
        this.$dialog.alert({
          message: "已提交，请稍等！",
          theme: "round-button",
        });
        return false;
      }
      this.loading = true;
      this.submit_disabled = true;

      this.$http.post("wx_work_add_patient", this.orderForm).then((res) => {
        this.loading = false;
        this.submit_disabled = false
        if(res.data.code !== 200){
          this.$dialog.alert({
            message: res.data.msg,
            theme: "round-button"
          })
          return false;
        }
        this.$dialog
          .alert({
            message: res.data.msg,
            theme: "round-button",
          })
          .then((res) => {
            this.$router.go(0);
          });
      });
    },

  },
  
  
  // watch: {
  //   "orderForm.city_code": function(val) {
  //     console.log("更改城市" + val);
  //     this.getHospital()
  //   }
  // },
  mounted() {
    if (this.$route.query.from_user) {
      localStorage.from_user = this.$route.query.from_user;
    }
  },
};
</script>
<style>
.van-field__label {
  width: 7.2em !important;
}
</style>
<style scoped>
.container {
  padding-left: 0px;
  padding-right: 0px;
}
.appointment {
  background: url(/static/images/yuyue_bg.png) no-repeat fixed top;
  padding-bottom: 45px;
}
/* .appointment form {
  padding: 10px;
} */
.van-cell-group__title {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 16px;
  color: #07c160;
}
.van-cell {
  padding: 16px !important;
}
.van-search .van-cell {
  padding: 10px !important;
}
.time_select {
  background-color: #07c160 !important;
  color: white !important;
}
.notice {
  text-align: left;
}
</style>
<style>
.appointment .mu-form-item-label {
  text-align: left !important;
}
</style>